.App {
    text-align: center;
    width: 95%;
    max-width: 600px;
    margin: auto;
}

.Day.holiday, .Day.holiday:hover, .Day.holiday.highlight{
    --festive-color: #e74c3c;
    background-color: var(--festive-color);
    color: aliceblue;
    border-color: var(--festive-color);
 }

.Day.holiday .DayNumber {
    font-weight: bolder;
}

.Day {
    --color: #27ae60;
    position: relative;
    grid-template-rows: auto 1fr auto 1fr;
    padding: 5px;
    display: inline-grid;
    border: solid var(--color) 1px;
    margin: 2px;
    grid-template-areas: "header"
                         "."
                         "content"
                         ".";
    height: 3em;
    transition: all 0.3s;
    background-color: aliceblue;
    text-align: center;
    color: var(--color);
    --shadow-color: rgba(0,0,0,0.5);
}

.Day:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    box-shadow: 1px 1px 16px 0 var(--shadow-color);
}


.Day.disabled:before{
    box-shadow: none;
}

.Day.disabled{
    background-color: #fafafa;
    /*color: #7f8c8d;*/
    border-color: #fafafa;
    box-shadow: none;
    position: relative;
    z-index: -2;
}

.Day:hover, .Day.highlight{
    background-color: var(--color);
    color: aliceblue;
}

.Day:hover {
    z-index: 2;
    transform: scale(1.1, 1.1);
}

.Day.highlight {
    z-index: 3;
    transform: scale(1.15, 1.15);
}

.DayNumber {
    grid-area: header;
    font-size: 0.75em;
}

.Shift {
    grid-area: content;
    font-weight: bold;
    font-size: 1.25em;
}

.MonthCalendar{
    display: grid;
    grid-template-columns: repeat(7, auto);
}

.button {
    display: inline-block;
    margin: 10px;
}

.month {
    font-weight: 500;
    font-size: 1.5em;
    color: #2980b9;
}

.Select {
    text-align: left;
}

.bigImage {
    width: 40%;
}

.dayName {
    margin: 0.5em;
    color: rgba(0, 0, 0, 0.3);
}

.tip {
    margin-top: 2em;
    margin-bottom: 1em;
}

.tip svg{
    vertical-align: middle;
}

.changeMonth .smallButton{
     display: none;
 }

@media (max-width: 380px) {
    .changeMonth .smallButton{
        display: inline-block;
    }

    .changeMonth .bigButton{
        display: none;
    }
}

a {
    color: #2980b9;
    text-decoration: none;
    transition: color 0.5s;
}

a:hover {
    color: #2c3e50;
    text-decoration: none;
}
